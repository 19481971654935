<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-20 13:22:31
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-22 13:51:40
-->
<template>
  <div>
    <div class="content">
      <div class="info-head">{{ infoData.title }}</div>
      <div class="info-msg">
        <span class="info-msg_desc"> {{ infoData.time }} 来源：鸡思广益</span>
        &nbsp; &nbsp;
        <a href="javascript:void(0)" @click="copyUrl">复制链接</a>
        &nbsp;
        <a href="javascript:void(0)" @click="addfavorite">添加至收藏夹</a>
      </div>
      <div class="middle">
        <div v-html="setHtml(infoData.html)" />
      </div>
      <div class="foot">
        <div class="foot-left">
          <div class="link" @click="golink('prev')">
            上一篇：<span style="color: #666">{{ prevData.title || "没有了" }}</span>
          </div>
          <div class="link" @click="golink('next')">
            下一篇：<span style="color: #666">{{ nextData.title || "没有了" }}</span>
          </div>
        </div>
        <div class="foot-right">
          分享到&nbsp;
          <el-tooltip class="item" effect="dark" content="分享到QQ空间" placement="top"> <img @click="hanleShare('qq')" src="../../assets/news/qqkj.png" alt="" /> </el-tooltip>&nbsp;
          <el-tooltip class="item" effect="dark" content="分享到新浪微博" placement="top"> <img @click="hanleShare('wb')" src="../../assets/news/wb.png" alt="" /> </el-tooltip>&nbsp;
          <el-tooltip class="item" effect="dark" content="分享到微信" placement="top">
            <img @click="hanleShare('wx')" src="../../assets/news/wx.png" alt="" />
          </el-tooltip>
        </div>
      </div>
      <div v-show="flag" class="code-box">
        <canvas id="QRCode_header"></canvas>
        <div style="background-color: #fff">微信扫一扫分享至朋友圈</div>
        <div class="close" @click="flag = false">×</div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsInfo } from "../new/new"
import QRCode from "qrcode" //引入生成二维码插件
export default {
  components: {},
  data() {
    return {
      infoData: {},
      prevData: {},
      nextData: {},
      QRCodeMsg: "QRCode_header",
      flag: false,
    }
  },
  methods: {
    // 获取html内容
    getHtml(id) {
      const _obj = newsInfo.filter((item) => item.id === id)[0]
      this.infoData = _obj
      const _ids = newsInfo.map((item) => item.id)
      const _index = _ids.indexOf(id)
      if (_index !== -1) {
        if (_index !== 0) {
          this.prevData = newsInfo[_index - 1]
        } else {
          this.prevData = {}
        }
        if (_index !== newsInfo.length - 1) {
          this.nextData = newsInfo[_index + 1]
        } else {
          this.nextData = {}
        }
      }
    },
    setHtml(str) {
      if (!this.$store.getters.getIsPhone) {
        if (str) {
          const _str = str.replaceAll("<img", "<img style='width:100%'")
          return _str
        }
      }
      return str
    },
    // 复制地址
    copyUrl() {
      const url = window.location.href
      if (navigator.userAgent.toLowerCase().indexOf("ie") > -1) {
        window.clipboardData.setData("Text", url)
        alert("该内容已复制到剪切板！")
      } else {
        prompt("非IE内核浏览器，请复制以下内容：", url)
      }
    },
    // 添加收藏
    addfavorite() {
      const ctrl = navigator.userAgent.toLowerCase().indexOf("mac") != -1 ? "Command/Cmd" : "CTRL"

      try {
        if (document.all) {
          //IE类浏览器
          try {
            window.external.toString() //360浏览器不支持window.external，无法收藏
            window.alert("国内开发的360浏览器等不支持主动加入收藏。\n您可以尝试通过浏览器菜单栏 或快捷键 ctrl+D 试试。")
          } catch (e) {
            try {
              window.external.addFavorite(window.location, document.title)
            } catch (e) {
              window.external.addToFavoritesBar(window.location, document.title) //IE8
            }
          }
        } else if (window.sidebar) {
          //firfox等浏览器
          window.sidebar.addPanel(document.title, window.location, "")
        } else {
          alert("您可以尝试通过快捷键" + ctrl + " + D 加入到收藏夹~")
        }
      } catch (e) {
        window.alert(
          "因为IE浏览器存在bug，添加收藏失败！\n解决办法：在注册表中查找\n HKEY_CLASSES_ROOT\\TypeLib\\{EAB22AC0-30C1-11CF-A7EB-0000C05BAE0B}\\1.1\\0\\win32 \n将 C:\\WINDOWS\\system32\\shdocvw.dll 改为 C:\\WINDOWS\\system32\\ieframe.dll "
        )
      }
    },
    // 上下篇查看
    golink(type) {
      if (type === "prev") {
        if (this.prevData?.id) {
          this.$router.replace({ path: "/news/info", query: { id: this.prevData.id } })
          this.getHtml(this.prevData.id)
        }
      } else if (type === "next") {
        if (this.nextData?.id) {
          this.$router.replace({ path: "/news/info", query: { id: this.nextData.id } })
          this.getHtml(this.nextData.id)
        }
      }
    },
    // 分享
    hanleShare(type) {
      const _url = encodeURIComponent(window.location.href)
      const _title = encodeURI(this.infoData.title)
      const _pic = encodeURI("https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708416472659.png")
      if (type === "qq") {
        const qqPath = `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${_url}&title=${_title}&pics=${_pic}`
        window.open(qqPath, "qq分享")
      } else if (type === "wb") {
        const wbPath = `http://service.weibo.com/share/share.php?url=${_url}&title=${_title}&appkey=801cf76d3cfc44ada52ec13114e84a96`
        window.open(wbPath, "微博分享")
      }
      if (type === "wx") {
        this.flag = true
      }
    },
    // 二维码生成
    getQRCode() {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 12, //二维码留白边距
        width: 200, //宽
        height: 180, //高
        text: window.location.href, //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      }
      this.QRCodeMsg = window.location.href //生成的二维码为URL地址js
      let msg = document.getElementById("QRCode_header")
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.QRCodeMsg, opts, function (error) {
        console.log(error)
      })
    },
  },
  mounted() {
    const _id = this.$route.query.id
    if (_id) {
      this.getHtml(_id)
    }
    this.getQRCode()
  },
}
</script>
<style lang="scss" scoped>
.info-head {
  width: 100%;
  color: #000;
  font-size: 28px;
  text-align: center;
  padding-bottom: 10px;
}
.info-msg {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding-bottom: 20px;
  .info-msg_desc {
    color: #a2a7b2;
    font-size: 13px;
    text-align: center;
  }
}
.content {
  max-width: 1200px;
  margin: 0 auto;
}
.middle {
  width: 100%;
  padding-bottom: 30px;
}
.foot {
  width: 100%;
  border-top: 2px solid #eae9e9;
  height: 94px;
  display: flex;
  justify-content: space-between;
  .foot-left {
    .link {
      margin-top: 10px;
      font-size: 16px;
      color: #181818;
    }
    .link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .foot-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #666;
    img {
      width: 25px;
      cursor: pointer;
    }
  }
}
.code-box {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 30%;
  left: 50%;
  background-color: #fff;
  text-align: center;
  color: #181818;
  font-size: 14px;
  .close {
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 26px;
    cursor: pointer;
  }
}
</style>
